// react
import React from "react";
import {RiChatPollLine} from "react-icons/ri";
import {Dropdown} from "react-bootstrap";

// local
import {Client, CURRENT_MONTH, CURRENT_YEAR, PollResponse, PollSummary} from "../../types";
import {SurveyResponses} from "./SurveyResponses";
import {SurveySummary} from "./SurveySummary";
import {NavigateFunction} from "react-router";
import {Loading} from "../../components";

/**
 * SurveySection
 */

interface SectionProps {
	client: Client
	navigate: NavigateFunction,
}

interface SectionState {
	clientName: string,
	companyId: string,
	loaded: boolean,
	summary: PollSummary[],
	breakdown: PollResponse[],
	timeframe: string,
	survey: string,
	year: number,
	month: number,
	startYear: number,
	startMonth: number,
	endYear: number,
	endMonth: number,
}

export class SurveySection extends React.Component<SectionProps, SectionState> {

	ctlMonths = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec' ];

	constructor(props: SectionProps) {
		// setup
		super(props);
		const {client} = props;

		this.state = {
			clientName: client.name,
			companyId: "",
			loaded: false,
			summary: [],
			breakdown: [],
			timeframe: 'CPOTM-' + CURRENT_YEAR + '-' + CURRENT_MONTH,
			survey: "",
			startYear: 0,
			startMonth: 0,
			endYear: 0,
			endMonth: 0,
			year: CURRENT_YEAR,
			month: CURRENT_MONTH
		};

		// Register the callbacks.
		this.onChooseTimeframe = this.onChooseTimeframe.bind(this);
		this.onSelectCompany = this.onSelectCompany.bind(this);
	}

	componentDidMount = async () => {
		// setup
		const {client} = this.props;
		const {clientName, timeframe, companyId} = this.state;

		// Get the possible surveys (historical and current).
		const results = await client.fetchSurveyAnalytics(clientName, timeframe, true);
		const range = results.summary;

		// Intial seed is the current month.
		this.setState({startYear: range[0].year, startMonth: range[0].month,
			endYear: range[1].year, endMonth: range[1].month});
		this.fetchSurvey(clientName, companyId, timeframe, false);
	}

	componentDidUpdate = async (pProps: any, pState: any) => {
		// setup
		const {clientName, timeframe} = this.state;

		if (pProps.client.name !== clientName) {
			this.fetchSurvey(pProps.client.name, "", timeframe, false);
		}
	}

	fetchSurvey = async (clientName: string, companyId: string, timeframe: string, force: boolean) => {
		// setup
		const {client} = this.props;

		const poll = await client.fetchSurveyAnalytics(clientName, timeframe, force);
		this.setState({loaded: true, clientName, companyId, timeframe: poll.timeframe, summary: poll.summary, breakdown: poll.breakdown});
	}

	onChooseTimeframe(eventKey: any) {
		// setup
		const {clientName, companyId} = this.state;
		const parts = eventKey.split('-');
		const timeframe = eventKey;
		this.setState({survey: parts[0], timeframe, year: parts[1], month: parts[2]});

		this.fetchSurvey(clientName, companyId, timeframe, false);
	}

	onSelectCompany(companyId: string) {
		// setup
		const {client, navigate} = this.props;
		const {survey} = this.state;

		client.setDepartment(companyId);
		this.setState({companyId: (this.state.companyId === companyId) ? "" : companyId});
	}

	render() {
		// setup
		const {loaded, year, month} = this.state;

		// render
		if (!loaded) {
			return <Loading/>;
		}
		return (
			<div className='section'>
				<div className='section-header'>
					<div className='col-6'>
						<RiChatPollLine className="section-icon" size={28}/>
						Survey Information
						{this.renderTimeframe()}
					</div>
				</div>
				{this.renderSurveys(year, month)}
				<hr/>
			</div>
		)
	}

	renderSurveys(year: number, month: number) {
		// setup
		const {companyId, summary, breakdown, survey} = this.state;
		const fBreakdown = (companyId && companyId !== 'All Departments') ?
			breakdown.filter(p => p.company === companyId) :
			breakdown;

		// render
		return <div className="row">
			<div className="column-50">
				<SurveySummary
					year={year}
					month={month}
					summary={summary}
					onSelectCompany={this.onSelectCompany}
					companyId={companyId}
				/>
			</div>
			<div className="column-50">
				<SurveyResponses
					survey={survey}
					year={year}
					month={month}
					responses={fBreakdown}
				/>
			</div>
		</div>;
	}

	renderTimeframe() {
		// setup
		const {startYear, startMonth, endYear, endMonth, year, month} = this.state;
		const defaultValue = '- ' + this.ctlMonths[month-1] + " '" + (year - 2000);

		// Determine the available months.
		let m = startMonth;
		let y = startYear;
		let items: any = [];
		while (y < endYear || (y == endYear && m <= endMonth)) {
			const key = `CPOTM-${y}-${m}`;
			const value = '- ' + this.ctlMonths[m-1] + " '" + (y - 2000);
			items.unshift(<Dropdown.Item key={key} eventKey={key}>{value}</Dropdown.Item>);
			if (m++ == 12) {
				y++;
				m = 1;
			}
		}

		// render
		return <Dropdown className="survey-timeframe" onSelect={this.onChooseTimeframe}>
			<Dropdown.Toggle variant="success" id="dropdown-basic">
				{defaultValue}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{items}
			</Dropdown.Menu>
		</Dropdown>
	}

}

