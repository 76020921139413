// react
import React from "react";
import {RiCouponLine} from "react-icons/ri";

// local
import {Client, CouponInfo, CouponUseInfo, CourseInfo, SalesInfo, User} from "../../types";
import {CouponsSummary} from "./CouponsSummary";
import {Coupons} from "./Coupons";
import {NavigateFunction} from "react-router-dom";
import {IoReload} from "react-icons/io5";
import {Button} from "react-bootstrap";
import {Loading, NavButton, NavSearch, TimeframeDropdown} from "../../components";
import {AiOutlineCreditCard} from "react-icons/ai";

/**
 * CourseSection
 */

interface SectionProps {
	user: User,
	client: Client,
	navigate: NavigateFunction,
}
interface SectionState
{
	loaded: boolean,
	summary: SalesInfo[],
	breakdown: CouponInfo[],
	fBreakdown: CouponInfo[],
	search: string,
	startDate: string,
	endDate: string,
	couponId: string,
	clientName: string
}

export class CouponsSection extends React.Component<SectionProps, SectionState> {

	constructor(props: SectionProps) {
		super(props);
		this.state = {
			loaded: false,
			summary: [],
			breakdown: [],
			fBreakdown: [],
			couponId: '',
			search: '',
			clientName: props.client.name,
			startDate: props.client.couponsStartDate,
			endDate: props.client.couponsEndDate
		};

		// Register the callbacks.
		this.onClearSearch = this.onClearSearch.bind(this);
		this.onGenerateReport = this.onGenerateReport.bind(this);
		this.onReloadList = this.onReloadList.bind(this);
		this.onSelectItem = this.onSelectItem.bind(this);
		this.onSubmitTimeframe = this.onSubmitTimeframe.bind(this);
		this.onUpdateSearch = this.onUpdateSearch.bind(this);
	}

	componentDidMount = async () => {
		// setup
		this.fetchCoupons(false);
	}

	componentDidUpdate = async (pProps: any, pState: any) => {
		// setup
		const {client} = this.props;
		const {clientName, startDate, endDate} = this.state;

		// See if we need changes.
		if (pState.clientName !== client.name) {
			await this.setState({clientName: client.name});
		}
		if (pState.clientName !== clientName ||
			pState.startDate !== startDate || pState.endDate !== endDate) {
			this.fetchCoupons(true);
		}
	}

	fetchCoupons = async (force: boolean) => {
		// setup
		const {client} = this.props;
		const {startDate, endDate, search} = this.state;

		// Filter the coupons.
		const data = await client.fetchCouponAnalytics(client.name, startDate, endDate, force);
		const summary = data.summary;
		const breakdown = data.breakdown;
		const couponId = summary.length ? summary[0].coupon : '';
		const fBreakdown = this.filterBySearch(breakdown, couponId, search);
		this.setState({loaded: true, clientName: client.name, summary, breakdown, fBreakdown, couponId});
	}

	filterBySearch(breakdown: CouponInfo[], couponId: string, search: string): CouponInfo[] {
		// setup
		if (!couponId) {
			return [];
		}

		// See if there are any matches.
		let fBreakdown = breakdown.filter(item => item.coupon_category === couponId);
		if (search) {
			search = search.toLowerCase();
			fBreakdown = fBreakdown.filter(item => item.search.includes(search));
		}
		return fBreakdown;
	}

	onClearSearch(event: any) {
		// setup
		const {breakdown, couponId, search} = this.state;
		const target = event.target.previousSibling;
		target.value = '';

		// Filter by the search value.
		if (target.value !== search) {
			const fBreakdown = this.filterBySearch(breakdown, couponId, target.value);
			this.setState({fBreakdown, search: target.value});
		}
	}

	onGenerateReport = async () => {
		// setup
		const {client} = this.props;
		const {startDate, endDate} = this.state;

		// Generate and display the report.
		await client.generateReport(client.name, 'codes', startDate, endDate);
	}

	onReloadList = async () => {
		// Refetch the list.
		this.fetchCoupons(true);
	}

	onSelectItem(couponId: string) {
		// setup
		const {breakdown, search} = this.state;
		couponId = (this.state.couponId !== couponId) ? couponId : '';

		// Re-filter the answer
		const fBreakdown = this.filterBySearch(breakdown, couponId, search);
		this.setState({couponId, fBreakdown});
	}

	onSubmitTimeframe(startDate: string, endDate: string) {
		// Update the settings.
		this.setState({startDate, endDate});
		//this.props.client.setCouponsTimeframe(startDate, endDate);
	}

	onUpdateSearch(event: any) {
		// setup
		const {breakdown, couponId, search} = this.state;
		const target = event.target;

		// Filter by the search value.
		if (target.value !== search) {
			const fBreakdown = this.filterBySearch(breakdown, couponId, target.value);
			this.setState({fBreakdown, search: target.value});
		}
	}

	render() {
		// render
		return (
			<div className='section'>
				{this.renderSectionHeader()}
				{this.renderCouponTable()}
				<hr/>
			</div>
		)
	}

	renderCouponTable() {
		// setup
		const {client, navigate, user} = this.props;
		const {summary, fBreakdown, couponId, loaded} = this.state;

		// render
		if (!loaded) {
			return <div className="row" style={{height: 20}}>
				<Loading className='vt-center'/>
			</div>;
		}
		return <div className="row">
			<div className="column-50">
				<CouponsSummary
					client={client}
					coupons={summary}
					couponId={couponId}
					onSelectItem={this.onSelectItem}
				/>
			</div>
			<div className="column-50">
				<Coupons
					user={user}
					navigate={navigate}
					coupons={fBreakdown}
				/>
			</div>
		</div>
	}

	renderSectionHeader() {
		// render
		return <div className='section-header'>
			<RiCouponLine className="section-icon" size={28}/>
			Affiliate Codes
			<IoReload
				className='section-reload'
				data-tooltip-id="lct-reporting-tooltip"
				data-tooltip-content="Click to refresh list"
				data-tooltip-place='right'
				size={18}
				onClick={this.onReloadList}
			/>

			{ /* actions */ }
			<Button
				className='navigate-report'
				variant='secondary'
				size="sm"
				href={'https://www.aaadrivingcourse.com'}
				target='_blank'
				rel="noreferrer"
			>
				<div className='nav-icon-wrapper'>
					<AiOutlineCreditCard className="nav-icon" size={18}/>
				</div>
				Purchase Codes
			</Button>
			<NavButton
				className='navigate-report'
				text='Generate Report'
				icon={<RiCouponLine className="nav-icon" size={18}/>}
				onClick={this.onGenerateReport}
			/>
			<NavSearch
				text='Search for...'
				onClearSearch={this.onClearSearch}
				onUpdateSearch={this.onUpdateSearch}
			/>
		</div>;
	}
}

