// react
import React from "react";
import {TbSchool} from "react-icons/tb";

// local
import {Client, User} from "../../types";
import {CoursesSummary} from "./CoursesSummary";
import {CoursesViews} from "./CoursesViews";
import {Button} from "react-bootstrap";
import {NavigateFunction} from "react-router";

/**
 * CoursesSection
 */

interface SectionProps {
	user: User,
	client: Client,
	navigate: NavigateFunction
}
interface SectionState {
	activityId: string,
	clientName: string
}

export class CoursesSection extends React.Component<SectionProps, SectionState> {

	constructor(props: SectionProps) {
		super(props);
		const {client} = props;

		this.state = {
			clientName: client.name,
			activityId: ""
		};

		// Register the callbacks.
		this.onNavigateCourses = this.onNavigateCourses.bind(this);
		this.onSelectActivity = this.onSelectActivity.bind(this);
	}

	componentDidUpdate = async (pProps: any, pState: any) => {
		// setup
		const {client} = this.props;

		// See if we need changes.
		if (pState.clientName !== client.name) {
			await this.setState({clientName: client.name});
		}
	}

	onNavigateCourses() {
		// Go to the courses page.
		this.props.navigate("/courses");
	}

	onSelectActivity(activityId: string) {
		this.setState({activityId: (this.state.activityId === activityId) ? "" : activityId});
	}

	render() {
		// setup
		const {client} = this.props;
		const {activityId, clientName} = this.state;

		// render
		return (
			<div className='section'>
				<div className='section-header'>
					<TbSchool className="section-icon" size={28}/>
					Course Information
				</div>

				<div className="row">
					<div className="column-50">
						<CoursesSummary
							client={client}
							clientName={clientName}
							onSelectActivity={this.onSelectActivity}
							activityId={activityId}
						/>
					</div>
					<div className="column-50">
						<CoursesViews
							client={client}
							clientName={clientName}
							activityId={activityId}
						/>
					</div>
				</div>

				<hr/>
			</div>
		)
	}

}

